export const 
	USER_LOAD_REQ 			= 'USER_LOAD_REQ',
	USER_LOAD_SUCCESS 		= 'USER_LOAD_SUCCESS',
	USER_LOAD_ERROR 		= 'USER_LOAD_ERROR',

	USER_UPDATE_REQ 		= 'USER_UPDATE_REQ',
	USER_UPDATE_SUCCESS 	= 'USER_UPDATE_SUCCESS',
	USER_UPDATE_ERROR 		= 'USER_UPDATE_ERROR',

	USER_AVATAR_UPLOAD_REQ	= 'USER_AVATAR_UPLOAD_REQ',

	USER_LOGIN_PASSWORD 	= 'USER_LOGIN_PASSWORD',
	USER_REGISTER_PASSWORD 	= 'USER_REGISTER_PASSWORD',
	USER_LOGIN_NATIVE 		= 'USER_LOGIN_NATIVE',
	USER_LOGIN_JWT			= 'USER_LOGIN_JWT',
	USER_LOGIN_TFA			= 'USER_LOGIN_TFA',
	
	USER_LOST_PASSWORD 		= 'USER_LOST_PASSWORD',
	USER_LOST_PASSWORD_SUCCESS= 'USER_LOST_PASSWORD_SUCCESS',
	USER_RECOVER_PASSWORD	= 'USER_RECOVER_PASSWORD',

	USER_NOT_AUTHORIZED 	= 'USER_NOT_AUTHORIZED',

	USER_REFRESH_REQ 		= 'USER_REFRESH_REQ',

	USER_LOGOUT_REQ 		= 'USER_LOGOUT_REQ',

	USER_SUBSCRIPTION_LOAD_REQ		= 'USER_SUBSCRIPTION_LOAD_REQ',
	USER_SUBSCRIPTION_LOAD_SUCCESS	= 'USER_SUBSCRIPTION_LOAD_SUCCESS',
	USER_SUBSCRIPTION_LOAD_ERROR	= 'USER_SUBSCRIPTION_LOAD_ERROR',

	USER_BACKUP				= 'USER_BACKUP',

	USER_TFA_CONFIGURE		= 'USER_TFA_CONFIGURE',
	USER_TFA_VERIFY			= 'USER_TFA_VERIFY',
	USER_TFA_REVOKE			= 'USER_TFA_REVOKE'