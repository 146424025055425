export const hotkeys = {
    async getAll() {
        return []
    },

    async getByName(name) {

    },

    link() {
        return undefined
    }
}